.history-container {
    max-width: 1366px;
    margin: auto;
}
.wrapper-table-history {
    width: 100%;
}
.history-product img {
    max-width: 75px;
}
.history-product a {
    display: inline-block;
    margin: auto 13px;
    font-weight: 400;
    color: #333;
}
.history-product a:hover {
    text-decoration: none;
}
.buttons-history-action {
    align-items: center;
}
.buttons-history-action button {
    border: solid 1px #f1f1f1;
}
.buttons-history-action .total {
    min-width: 35px;
    transition: 0.2s ease-in;
}
.buttons-history-action button:hover {
    background-color: #f1f1f1;
    color: #333;
}
.buttons-history-action button:focus {
    outline: 0;
    box-shadow: none
}
.buttons-history-action button:active {
    box-shadow: inset -2px 2px 2px #c9c9c9;
}
.product-details {
    margin: auto 10px;
}
.input-zip-history {
    padding: 5px 10px;
    border-radius: 2px;
    border: solid 1px #cecece;
}
.input-zip-history:focus {
    outline: 0;
    box-shadow: none;
}
.container-order {
    padding: 10px;
    background-color: #f7f7f7;
    margin-bottom: 20px;
}
.details-order {
    padding: 15px;
    margin: -10px;
    background-color: #ebebeb;
    color: #424242;
    font-size: 16px;
}
@media (max-width: 992px) {
    .buttons-history-action button:active {
        box-shadow: none;
    }
}
@media (max-width: 640px) {
    .history-product a {
        font-size: 1.2rem;
        line-height: 120%;
    }
    .history-product {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: solid 1px #e6e6e6;
    }
    .history-product:nth-child(n-1) {
        border-bottom: none;
        margin-bottom: 0;
    }
}