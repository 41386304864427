.error-wrapper {
    position: relative;
    width: 100%;
    max-width: 560px;
    margin: 35px auto;
    text-align: center;
    padding: 0;
}
.error-wrapper .title {
    font-size: 32px;
    font-weight: 700;
    color: #000;
}
.error-wrapper .info {
    font-size: 14px;
}
.home-btn, 
.home-btn:focus, 
.home-btn:visited {
    text-decoration: none;
    font-size: 18px;
    color: var(--btn-primary-color);
    padding: 17px 2.5rem;
    border: 1px solid var(--btn-primary-color-border);
    border-radius: 3px;
    display: block;
    margin: 20px auto;
    max-width: 100%;
    background-color: transparent;
    outline: 0;
    transition: .2s ease-in all;
}
.home-btn:hover {
    text-decoration: none;
    font-size: 18px;
    background-color: var(--primary-color-hover);
    padding: 17px 2.5rem;
    border-radius: 3px;
    display: block;
    margin: 20px auto;
    max-width: 100%;
    color: #ffffff;
    outline: 0;
}
.man-icon {
    display: inline-block;
    height: 250px;
    width: 250px;
    max-width: 100%;
    margin-bottom: 16px;
    background-size: cover!important;
}