.wrapper-carousel-product .carousel .thumb.selected,
.carousel .thumb:hover {
  border: 1px solid #c9c9c9;
}
.wrapper-carousel-product .carousel .thumb {
  border: 1px solid #fff;
}
.wrapper-carousel-product .slider-product {
  width: 100%;
  max-width: 500px;
  min-height: 524px;
  margin: auto;
}
.wrapper-carousel-product .slider-product:focus {
  pointer-events: visible;
}
.wrapper-carousel-product .carousel .slide {
  background-color: #ffffff;
}
.wrapper-carousel-product .carousel .thumbs {
  padding: 0;
  text-align: center;
}
.wrapper-carousel-product .carousel.carousel-slider {
  position: static;
}
.wrapper-carousel-product {
  position: relative;
}
.wrapper-carousel-product .carousel.carousel-slider .control-arrow {
  bottom: 25px;
  top: unset;
  z-index: 1;
  color: black;
}
.btn-action-product {
  padding: 10px 20px;
  text-align: center;
  width: 100%;
  max-width: 250px;
  background-color: var(--btn-primary-color);
  border: solid 3px var(--btn-primary-color-border) !important;
  font-weight: 600;
}
.btn-action-product:focus,
.btn-action-product:hover,
.btn-action-product:active,
.btn-action-default:focus,
.btn-action-default:hover,
.btn-action-default:active {
  outline: 0 !important;
  box-shadow: none !important;
}
.btn-action-product:hover {
  padding: 10px 20px;
  text-align: center;
  width: 100%;
  background-color: var(--btn-primary-color-hover) !important;
  border: solid 3px var(--btn-primary-color-hover) !important;
}
.btn-action-default {
  padding: 10px 20px;
  text-align: center;
  width: 100%;
  max-width: 250px;
  background-color: #fff !important;
  border: solid 3px #c9c9c9;
  color: #333333;
  font-weight: 600;
}
.btn-action-default:hover {
  background-color: #a1a1a1 !important;
  color: #fff;
  border: solid 3px #a1a1a1;
}
.attribute-label {
  display: block;
  font-weight: bold;
  font-size: 1.8rem;
}
.attribute-content {
  margin-bottom: 20px;
  border-bottom: solid 1px #c9c9c9;
  padding-bottom: 30px;
  margin-top: 20px;
}
select.attribute-select {
  width: 100%;
  max-width: 250px;
  height: 50px;
  border: solid 1px #c9c9c9;
  border-radius: 1px;
  background-color: #fff;
  font-size: 1.2rem;
}
select.attribute-select:focus {
  outline: 0;
  box-shadow: none;
}
.similar-product {
  width: 100%;
  max-width: 250px;
  border-radius: 6px;
  border: solid 1px #e4e4e4;
  margin: 0 5px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 20px #ededed;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  color: initial;
}
.similar-product:hover {
  text-decoration: none;
  background-color: #f7f7f7;
  transition: 0.2s ease-in;
}
.similar-product-img {
  width: 100%;
  text-align: center;
  margin: auto;
  padding: 15px 20px;
}
.similar-product-img img {
  width: auto;
  max-width: 100%;
  margin: auto;
  max-height: 150px;
}
.similar-product-details {
  width: 100%;
}
.similar-product-price {
  font-size: 1.7rem;
  font-weight: 400;
}
.similar-product-name {
  font-size: 17px;
  color: #a2a9ae;
  font-weight: 300;
  line-height: 140%;
}
.similar-product-title {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 120%;
}
.similar-products .slick-slide > div {
  height: 100%;
  display: flex;
}
.similar-products .slick-slide {
  height: unset;
}
.similar-products .slick-track {
  display: inline-flex;
}
p.m-auto.out-of-stock {
  font-size: 1.4rem;
  color: #db3344;
}
.slider-product .lazy-load-image-background.blur.lazy-load-image-loaded {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 102vh;
  overflow: visible;
  overflow: initial;
  max-height: initial;
}
.slider-product .lazy-load-image-background.blur.lazy-load-image-loaded img {
  height: 100%;
}

.product-content {
  padding-top: 65px;
}

.redirect-link-edit-stamp {
  text-decoration: none;
}

@media (max-width: 992px) {
  .similar-product:hover {
    text-decoration: none;
    background-color: #ffffff;
  }
  .btn-action-product:hover {
    background-color: var(--btn-primary-color) !important;
    border: solid 3px var(--btn-primary-color-border) !important;
  }
  .btn-action-default:hover {
    background-color: #fff !important;
    border: solid 3px #c9c9c9 !important;
    color: #333333 !important;
  }
}
@media (max-width: 640px) {
  .wrapper-carousel-product .carousel.carousel-slider {
    position: relative;
  }
  .wrapper-carousel-product .slider-product {
    min-height: 275px;
  }
  .wrapper-carousel-product .carousel .control-arrow:before {
    padding: 0;
    margin: auto;
    border: none;
    font-weight: bold;
    color: #000;
    font-size: 1.6rem;
    top: 0;
  }
  .carousel .control-prev.control-arrow {
    left: -9%;
    top: 50%;
    margin-top: -25px;
  }
  .carousel .control-next.control-arrow {
    right: -9%;
    top: 50%;
    margin-top: -25px;
  }
  .similar-product {
    max-width: 100%;
  }
  .similar-product {
    box-shadow: 0px 0px 8px #ededed;
  }
}
@media (max-width: 576px) {
  .similar-products .slick-slide {
    width: 100% !important;
  }
  .btn-action-product,
  .btn-action-default,
  select.attribute-select {
    max-width: 100%;
  }
  .similar-product-title {
    font-size: 1.2rem;
  }
  .similar-product {
    max-width: 90%;
    flex: 0 0 100%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .similar-product-price {
    font-size: 0.9rem;
    margin: 0 -5px;
  }
  .similar-product-name {
    font-size: 15px;
    line-height: 160%;
  }
}
