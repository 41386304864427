.favorites-container {
    max-width: 1366px;
    margin: auto;
}
.title-page {
    font-weight: 400;
    width: 100%;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: solid 1px #d9d9d9;
}
/** PRODUCT GRID **/
.item-bn {
    border: solid 1px #b2b2b2;
    width: 100%;
    max-width: 1280px;
}
.item-product {
    width: 100%;
    max-width: 235px;
    border-radius: 6px;
    border: solid 1px #e4e4e4;
    margin: 0 15px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 20px #ededed;
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    color: initial;
    transition: .2s ease-out;
}
.item-product:hover {
    text-decoration: none;
    background-color: #f7f7f7;
    transition: .2s ease-in;
}
.item-product-img {
    width: 100%;
    text-align: center;
    margin: auto;
    padding: 15px 20px;
}
.item-product-img img {
    width: auto;
    max-width: 100%;
    margin: auto;
    max-height: 150px;
}
.item-product-details {
    width: 100%;
}
.item-product-price {
    font-size: 1.7rem;
    font-weight: 400;
}
.item-product-name {
    font-size: 17px;
    color: #a2a9ae;
    font-weight: 300;
    line-height: 120%;
}
.item-product-title {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 120%;
}
/** END PRODUCT GRID **/
@media (max-width: 576px) {
    /** PRODUCT GRID **/
    .item-product {
        max-width: 47%;
        flex: 0 0 100%;
        margin: 0 auto;
        margin-bottom: 10px;
    }
    .item-product-price {
        font-size: 1rem;
        word-break: break-word;
    }
    .item-product-name {
        font-size: 0.8rem;
    }
    /** END PRODUCT GRID **/
}