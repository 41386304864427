footer {
    margin-top: auto;
}
.footer {
    background-color: var(--footer-bg);
}
.footer-content{
    display: flex;
    flex-direction: row;
    font-size: 12px;
    background-color: #282626;
}
.footer-container {
    height: 100%;
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 1024px;
}
.footer-content-copyright {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
}
.footer-link-content {
    display: flex;
    flex-direction: column;
}
.footer-link-content > a {
    font-size: 13px;
    color: #ffffff;
}
.footer-description {
    border: 1px solid red;
    margin-top: 20px;
}
.footer-title{
    font-size: 15px;
}