.header-container {
  box-shadow: 0px 2px 3px #b3b3b3;
  margin-bottom: 15px;
  position: fixed;
  z-index: 101;
  left: 0;
  right: 0;
  top: 0;
}
.header-fallback {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  height: 146px;
}
.hidden-submenu .header-bottom {
  opacity: 0;
  max-height: 0;
  transition: max-height 0.2s ease-in-out;
  pointer-events: none;
}
.header-top img {
  min-height: 40px;
}
.header-middle {
  transition: padding 0.3s ease;
  background: var(--primary-color);
  padding: 0.675em 0;
  z-index: 101;
  top: 0;
  left: 0;
  right: 0;
}
.header-middle .feather-wrapper {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}
.feather-logo-link img {
  width: 10.25em;
}
.header-logo {
  margin: 0 auto;
  margin-right: 15px;
  flex: 0 1 auto;
}
a.feather-logo-link {
  display: block;
}
.header-search {
  width: 100%;
  padding: 0.625em 0.625em 0;
  position: relative;
  overflow: visible;
  flex: 0 1 auto;
}
.form-search-header {
  position: relative;
}
.form-search-header .src-input-wpr {
  position: relative;
  height: 100%;
}
.form-search-header .src-input {
  width: 100%;
  min-height: 39px;
  padding: 0.4em 0.4em 0.4em 3.5em;
  border-radius: 0;
  color: #000;
  background: #fff;
  border: 2px solid rgb(224, 223, 223);
  font-size: 1em;
  outline: 0;
  border-radius: 4px;
}
.submit-search-header,
.submit-search-header:hover,
.submit-search-header:focus {
  outline: 0;
  box-shadow: none;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border: 2px solid rgb(224, 223, 223);
  padding: 0 14px;
  font-size: 1.2rem;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.icon-search {
  color: rgb(192, 190, 190);
}

.header-user,
.header-favourite,
.header-shopping-cart {
  flex: 0 1 auto;
  color: #fff;
  font-weight: 300;
  display: inline-flex;
  align-items: center;
}
::-webkit-input-placeholder {
  color: rgb(192, 190, 190);
  padding-left: 8px;
}
span.header-user-icon {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
span.header-user-icon i {
  font-size: 35px;
}
span.header-favourite-icon {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
span.header-favourite-icon i {
  font-size: 35px;
}
span.header-shopping-cart-icon {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
span.header-shopping-cart-icon i {
  font-size: 35px;
}
.cursor-pointer {
  cursor: pointer;
}
.header-bottom {
  background-color: var(--primary-color);
  transition: 0.2s all ease-in;
}
.header-bottom .wrapper-ul {
  max-width: 1300px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
  white-space: nowrap;
}
.header-bottom ul {
  padding: 0;
  list-style: none;
  display: inline-block;
  margin-bottom: 0;
}
.header-bottom ul li {
  display: inline-block;
  padding: 10px;
  position: relative;
}
.header-bottom ul li .link {
  color: #fff;
  cursor: pointer;
  z-index: 2;
  position: relative;
  font-size: 0.875em;
}
.header-bottom ul li .link:hover {
  text-decoration: none;
}
.header-bottom ul li:hover:before {
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.header-bottom ul .category-menu-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary-color-before);
  z-index: 1;
  opacity: 0;
  box-sizing: border-box;
  -webkit-transform: scaleX(0.1);
  transform: scaleX(0.1);
  transition: all 0.7s ease;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.header-bottom ul .category-menu-item a {
  color: #282626;
}
.header-bottom ul .category-menu-item:hover a {
  color: #fff;
}

.header-bottom #dropdown-submenu-custom .category-menu-item a {
  color: initial;
  position: relative;
  z-index: 1;
}
.header-bottom #dropdown-submenu-custom .category-menu-item:hover a,
.header-bottom #dropdown-submenu-custom .category-menu-item a:hover {
  color: #fff;
  background-color: transparent;
}
.dropdown-custom.link:hover::before {
  display: none;
}
.dropdown-custom.link .medium-icon {
  font-size: 26px;
  display: inline-block;
  margin-right: 15px;
}
i.invert {
  transform: rotate(180deg);
  display: inline-block;
  transition: 0.2s ease-in all;
}
.dropdown-custom.link:hover i.invert,
.dropdown-custom.link:focus i.invert {
  transform: rotate(0deg);
  transition: 0.2s ease-out all;
}
.dropdown-custom.link {
  position: static;
}
.dropdown-custom:hover .dropdown-content,
.dropdown-content:hover {
  display: block;
  z-index: 2;
}
.dropdown-custom .dropdown-content {
  z-index: 10;
}
.dropdown-custom .dropdown-content li {
  margin: 0 !important;
}
.dropdown-custom .dropdown-content li:hover span {
  color: #fff;
  z-index: 1;
  position: relative;
}
.header-bottom ul li:not(.dropdown-custom) {
  margin: 0 10px;
}
.header-bottom ul li:not(.dropdown-custom):last-child {
  margin-right: 0;
}
/**MENU SIDEBAR MOBILE**/
.wrapper-menu-header {
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translate(-101%, 0);
  transition: 0.2s ease-out all;
}
.wrapper-menu-header.show {
  transform: translate(0, 0);
  transition: 0.2s ease-in all;
  background-color: #fff;
  box-shadow: 0 -2px 2px;
}
.wrapper-menu-header .default-ul {
  list-style: none;
  padding: 0;
  height: 0;
  max-height: calc(100vh - 390px);
  overflow-y: auto;
  transition: 0.2s ease-in;
}
.wrapper-menu-header .default-ul.active {
  height: 100%;
  transition: 0.3s ease-in;
}
.menu-sideber-header {
  line-height: 120%;
}
.open-menu-header {
  margin: 0 auto;
  margin-right: 10px;
}
.open-menu-header i {
  color: #282626;
  font-size: 30px;
}
.wrapper-menu-header .default-ul .default-li {
  padding: 1em 1em 0.5em 2.3em;
  font-size: 0.875em;
  color: #333;
  text-align: left;
}
.wrapper-menu-header .default-ul .default-li a,
.wrapper-menu-header .default-ul .default-li a:hover {
  color: initial;
  text-decoration: none;
}
.top-header-sidebar {
  width: 100%;
  padding: 15px 10px;
  background-color: var(--primary-color);
  color: #fff;
}
.wrapper-menu-header .open-menu-header {
  position: absolute;
  right: -63px;
  padding: 10px;
  margin: auto;
  background-color: var(--primary-color-hover);
  width: 100%;
  max-width: 63px;
  top: -1px;
  box-shadow: 1px 1px 2px #777879;
}
.icon-close-sidebar {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  height: 26px;
}
.icon-close-sidebar::before,
.icon-close-sidebar::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 31px;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #ffff;
  margin: 0 auto;
}
.icon-close-sidebar::before {
  transform: rotate(45deg) translate(13px, -13px);
}
.icon-close-sidebar::after {
  transform: rotate(-45deg) translate(13px, 13px);
}
.background-menu-header {
  background-color: #eb000049;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
/**FIM MENU SIDEBAR MOBILE**/
.dropdown-custom:hover .dropdown-user-menu {
  z-index: 3;
  background: #fff;
}
.menu-user-mobile {
  position: fixed;
  top: 0;
  right: 0;
  transform: translate(100%, 0);
  bottom: 0;
  transition: 0.2s ease-out;
  z-index: 10;
  background-color: #fff;
  width: 100%;
  max-width: 320px;
}
.menu-user-mobile.active {
  transform: translate(0, 0);
}
.background-menu-user {
  background-color: #00000049;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
header .user-avatar {
  width: 70px;
  height: 70px;
  background: #282626;
  border-radius: 35px;
  margin-bottom: 20px;
}
.top-header-sidebar .user-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
  margin-top: 15px;
}
.top-header-sidebar .user-avatar i {
  font-size: 70px;
  color: #ffffff;
}

.top-header-sidebar .options a {
  color: #fff;
  display: inline-block;
  margin: auto 10px;
}
.sidebar-mobile-footer {
  background-color: #e1dfdf;
}
.sidebar-mobile-footer a {
  color: #dc3545;
  font-weight: 500;
}
.item-sidebar-mobile i {
  font-size: 20px;
  margin-right: 10px;
}

.item-sidebar-mobile .icon-change {
  margin-right: 0;
  margin-left: 20px;
}

.item-sidebar-mobile .icon-change.active::before {
  transform: rotate(180deg);
  transition: 0.2s ease-in;
}
.item-sidebar-mobile .icon-change::before {
  transform: rotate(0deg);
  transition: 0.2s ease-in;
}
.header-user-icon {
  color: #282626;
}
.header-favourite-icon {
  color: #282626;
}
.header-shopping-cart-icon {
  color: #282626;
}
.text-shopping-cart-header {
  color: #282626;
}
.text-favourite-header {
  color: #282626;
}
.text-user-header {
  color: #282626;
}
.img-logo-wrapper {
  object-fit: cover;
  max-height: 85px;
  max-width: 100px;
  width: auto;
  height: auto;
}
@media screen and (min-width: 1025px) {
  .header-middle {
    padding: 0.7rem 0;
  }
  .header-middle .header-logo {
    text-align: center;
    margin-left: 0;
    min-width: 16em;
    position: relative;
  }
  .header-user,
  .header-favourite,
  .header-shopping-cart {
    min-width: 10em;
    text-align: left;
    padding: 0 0 0 3.5em;
    margin: 0 1em 0 0;
  }
}
@media (max-width: 1024px) {
  .dropdown-custom.link {
    display: none;
  }
  .wrapper-menu-header.show {
    background-color: #f1f1f1;
  }
}
@media (max-width: 992px) {
  .header-bottom ul li:hover:before {
    opacity: 0;
  }
  .wrapper-menu-header {
    width: 100%;
    max-width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .header-middle .feather-wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
  }
  .header-middle .header-logo {
    min-width: 11em;
    margin-right: 0;
  }
  .form-search-header .src-input {
    padding: 0.875em 1em 0.875em 3.4em;
    font-size: 1em;
  }
  .header-user,
  .header-favourite,
  .header-shopping-cart {
    position: relative;
    right: 0;
    width: 4em;
  }
}
@media (max-width: 768px) {
  .header-logo {
    display: inline-block;
    margin-left: 0;
    margin-right: 10px;
  }
  .img-logo-wrapper {
    max-width: 260px;
    max-height: 260px;
    width: auto;
    height: auto;
  }
  .feather-logo-link img {
    width: 8em;
  }
  .header-search {
    order: 3;
  }
  .header-user,
  .header-favourite,
  .header-shopping-cart {
    margin: auto;
  }
  span.header-user-icon {
    margin-right: 5px;
  }
  span.header-user-icon i {
    font-size: 25px;
  }
  span.header-favourite-icon i {
    font-size: 25px;
  }
  span.header-shopping-cart-icon i {
    font-size: 25px;
  }
  .wrapper-menu-header {
    width: 100%;
    max-width: 80%;
  }
  .header-bottom {
    display: none;
  }
  .header-fallback {
    height: 120px;
  }
}
