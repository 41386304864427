.settings-container {
    width: 100%;
    max-width: 1366px;
    margin: auto;
}
.section-settings fieldset {
    padding: 10px;
    background-color: #fcfcfc;
    box-shadow: 0px 2px 2px #f7f7f7;
    border-radius: 3px;
    border: solid 1px #e8e8e8;
}
.section-settings .title-section {
    margin: -15px 0;
    position: relative;
    top: -7px;
    text-shadow: 0px 1px 2px #d0d0d0;
    font-weight: 100;
}
.sharcode-link {
    padding: 6px;
    border-radius: 2px;
    background-color: #f1f1f1;
    font-size: 14px;
    color: #2196F3;
    cursor: pointer;
}
.heading { border-bottom:3px solid #ddd; }
.social-slide {
	height: 48px;
	width: 48px;
	margin: 10px;
    transition: all ease 0.3s;
}
.social-slide > div {
    width: 100%;
    height: 100%;
}
.social-slide > div:hover {
	background-position: 0px -48px;
	box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.8);
}