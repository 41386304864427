.login-container {
    max-width: 1366px;
    margin: 0 auto;
}
.login-wrapper{
    padding-top: 20px;
}
.terms {
    display: inline-flex;
}
.terms label {
    padding-left: 5px;
    min-height: 20px;
    font-weight: 300;
    cursor: pointer;
    user-select: none;
    line-height: 120%;
    font-size: 16px;
}
.change-section-login {
    line-height: 140%;
    text-align: center;
}
.change-section-login span {
    color: #0062cc;
    cursor: pointer;
    display: block;
}
@media (max-width: 768px) {
    .section-login {
        display: none;
        transition: .2s ease-out;
    }
    .section-login.active {
        display: block;
        transition: .2s ease-in;
    }
    .login-wrapper{
        padding-top: 55px;
    }
}