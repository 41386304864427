.container-search {
    max-width: 1300px;
    width: 100%;
}
.search-bn {
    border: solid 1px #b2b2b2;
    width: 100%;
    max-width: 1280px;
}
.search-product {
    width: 100%;
    max-width: 235px;
    border-radius: 6px;
    border: solid 1px #e4e4e4;
    margin: 0 5px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 20px #ededed;
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    color: initial;
    transition: .2s ease-out;
}
.search-product:hover {
    text-decoration: none;
    background-color: #f7f7f7;
    transition: .2s ease-in;
}
.search-product-img {
    width: 100%;
    text-align: center;
    margin: auto;
    padding: 15px 20px;
}
.search-product-img img {
    width: auto;
    max-width: 100%;
    margin: auto;
    max-height: 150px;
}
.search-product-details {
    width: 100%;
}
.search-product-price {
    font-size: 1.7rem;
    font-weight: 400;
}
.search-product-name {
    font-size: 17px;
    color: #a2a9ae;
    font-weight: 300;
    line-height: 120%;
}
.search-product-title {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 120%;
}
.sidebar-list {
    padding: 0;
    list-style: none;
    margin-bottom: 25px;
}
.title-sidebar {
    font-weight: 700;
    border-bottom: solid 1px #b2b2b2;
    padding-bottom: 15px;
    margin-bottom: 15px;
    font-size: 1.7rem;
}
li.sidebar-item {
    padding: 3px 0;
    margin: 5px auto;
    font-size: 18px;
    color: #757575;
    font-weight: 400;
    cursor: pointer;
}
li.sidebar-item a {
    color: initial;
}
.content-search-title {
    font-weight: 400;
    width: 100%;
    margin-bottom: 0;
}
.container-filter-order {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: solid 1px #d9d9d9;
    align-items: center;
}
.dropdown-custom.link.dropdown-searchs {
    display: none;
}
.wrapper-content-search {
    width: 100%;
    max-width: 992px;
    margin: auto;
}
.order-product-search {
    text-align: right;
    display: flex;
    width: auto;
    flex: 0 0 auto;
    align-items: center;
    font-size: 18px;
}
.order-product-search .order-product-select {
    padding: 10px 5px;
    border: solid 4px #efefee;
    outline: 0;
    box-shadow: none;
    color: var(--primary-color);
    letter-spacing: -1px;
    word-spacing: 1px;
    display: inline-block;
    background: #efefee;
}
.order-product-search label {
    margin-right: 10px;
}
.sidebar-searchs.show {
    right: 0;
    transition: 0.2s ease-out all;
}
.sidebar-search-title {
    width: 100%;
    padding: 13px 10px;
    background-color: #e9ecef;
    color: #6f6f6f;
    display: none;
}
.wrapper-content-search .open-sidebar-filter {
    font-size: 1rem;
    padding: .85em;
    white-space: nowrap;
    word-wrap: break-word;
    color: #666;
    border-radius: 0;
    border-width: 0;
    text-align: center;
    text-transform: lowercase;
    text-decoration: none;
    border-radius: 0;
    box-shadow: inset 0 -3px 0 rgba(0,0,0,0.15);
    transition: all 0.1s ease-in;
    background-color: #eee;
}
.background-sidebar-searchs {
    background-color: #00000049;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 101;
    display: none;
}
.background-sidebar-searchs.show {
    display: block;
}
.sidebar-searchs .open-sidebar-searchs {
    position: absolute;
    left: -63px;
    padding: 10px;
    margin: auto;
    background-color: #545b62;
    width: 100%;
    max-width: 63px;
    top: -1px;
    box-shadow: -1px 1px 2px #777879;
    display: none;
}
.sidebar-item.active {
    background-color: #dee2e6;
    color: #000000;
    margin-left: -5px;
    margin-right: -5px;
    padding: 3px 10px;
}
@media (max-width: 768px) {
    .sidebar-list-search {
        display: none;
    }
    .content-search-title {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
    .sidebar-search-title {
        display: block;
    }
    .sidebar-searchs .open-sidebar-searchs {
        display: block;
    }
    .sidebar-searchs {
        position: fixed;
        top: 0;
        z-index: 103;
        right: -100%;
        bottom: 0;
        transition: 0.2s ease-in all;
        background-color: #ffffff;
        box-shadow: -2px 0px 2px #757575;
        padding: 0;
        max-width: 320px;
    }
    .order-product-search {
        align-items: baseline;
        margin-top: 20px;
        background-color: #efefee;
        padding: 10px;
        padding-bottom: 5px;
    }
    .order-product-search .order-product-select {
        padding: 5px;
        border: solid 0px #efefee;
    }
}
@media (max-width: 576px) {
    .search-product {
        max-width: 100%;
        flex: 0 0 47%;
        margin: 0 auto;
        margin-bottom: 10px;
    }
    .search-product-price {
        font-size: 1rem;
        word-break: break-word;
    }
    .search-product-name {
        font-size: 0.8rem;
    }
    .order-product-search {
        width: 100%;
    }
    .sidebar-searchs {
        max-width: 80%;
    }
}