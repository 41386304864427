.container-group {
  padding-top: 70px;
  max-width: 1300px;
  width: 100%;
}
.group-bn {
  border: solid 1px #b2b2b2;
  width: 100%;
  max-width: 1280px;
}
.group-product {
  width: 100%;
  max-width: 245px;
  border-radius: 6px;
  border: solid 1px #e4e4e4;
  margin: 0 5px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 20px #ededed;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  color: initial;
  transition: 0.2s ease-out;
}
.group-product:hover {
  text-decoration: none;
  background-color: #f7f7f7;
  transition: 0.2s ease-in;
}
.group-product-img {
  width: 100%;
  text-align: center;
  margin: auto;
  padding: 15px 20px;
}
.group-product-img img {
  width: auto;
  max-width: 100%;
  margin: auto;
  max-height: 150px;
}
.group-product-details {
  width: 100%;
}
.group-product-price {
  font-size: 1.7rem;
  font-weight: 400;
}
.group-product-name {
  font-size: 17px;
  color: #a2a9ae;
  font-weight: 300;
  line-height: 120%;
}
.group-product-title {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 120%;
}
.sidebar-list {
  padding: 0;
  list-style: none;
  margin-bottom: 25px;
}
.title-sidebar {
  font-weight: 700;
  border-bottom: solid 1px #b2b2b2;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-size: 1.7rem;
}
li.sidebar-item {
  padding: 3px 0;
  margin: 5px auto;
  font-size: 18px;
  color: #757575;
  font-weight: 400;
  cursor: pointer;
}
li.sidebar-item a {
  color: initial;
}
.content-group-title {
  font-weight: 400;
  width: 100%;
  margin-bottom: 0;
}
.container-filter-order {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: solid 1px #d9d9d9;
  align-items: center;
}
.dropdown-custom.link.dropdown-groups {
  display: none;
}
.wrapper-content-group {
  width: 100%;
  margin: auto;
}
.order-product-group {
  text-align: right;
  display: flex;
  width: auto;
  flex: 0 0 auto;
  align-items: center;
  font-size: 18px;
}
.order-product-group .order-product-select {
  padding: 10px 5px;
  border: solid 4px #efefee;
  outline: 0;
  box-shadow: none;
  color: var(--primary-color);
  letter-spacing: -1px;
  word-spacing: 1px;
  display: inline-block;
  background: #efefee;
}
.order-product-group label {
  margin-right: 10px;
}
.sidebar-groups.show {
  right: 0;
  transition: 0.2s ease-out all;
}
.sidebar-group-title {
  width: 100%;
  padding: 13px 10px;
  background-color: #e9ecef;
  color: #6f6f6f;
  display: none;
}
.wrapper-content-group .open-sidebar-filter {
  font-size: 1rem;
  padding: 0.85em;
  white-space: nowrap;
  word-wrap: break-word;
  color: #666;
  border-radius: 0;
  border-width: 0;
  text-align: center;
  text-transform: lowercase;
  text-decoration: none;
  border-radius: 0;
  box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.1s ease-in;
  background-color: #eee;
}
.background-sidebar-groups {
  background-color: #00000049;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  display: none;
}
.background-sidebar-groups.show {
  display: block;
}
.sidebar-groups .open-sidebar-groups {
  position: absolute;
  left: -63px;
  padding: 10px;
  margin: auto;
  background-color: #545b62;
  width: 100%;
  max-width: 63px;
  top: -1px;
  box-shadow: -1px 1px 2px #777879;
  display: none;
}
.sidebar-item.active {
  background-color: #dee2e6;
  color: #000000;
  margin-left: -5px;
  margin-right: -5px;
  padding: 3px 10px;
}
.skeleton-group-banner .react-loading-skeleton {
  border-radius: 0;
  margin-bottom: 20px;
  height: 492px;
}
.row-skeleton .react-loading-skeleton {
  width: 235px;
  max-width: 235px;
  margin: auto 10px;
}
.group-banner-img {
  width: 100%;
  height: calc(25vw * 0.8);
  overflow: hidden;
  max-height: 492px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
}
.skeleton-group-banner .react-loading-skeleton {
  height: calc(50vw * 0.8);
  max-height: 492px;
}
@media (max-width: 1310px) {
  .group-product {
    max-width: 32%;
    flex: 0 0 32%;
    margin-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .sidebar-list-group {
    display: none;
  }
  .content-group-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .sidebar-group-title {
    display: block;
  }
  .sidebar-groups .open-sidebar-groups {
    display: block;
  }
  .sidebar-groups {
    position: fixed;
    top: 0;
    z-index: 103;
    right: -100%;
    bottom: 0;
    transition: 0.2s ease-in all;
    background-color: #ffffff;
    box-shadow: -2px 0px 2px #757575;
    padding: 0;
    max-width: 320px;
  }
  .order-product-group {
    align-items: baseline;
    margin-top: 20px;
    background-color: #efefee;
    padding: 10px;
    padding-bottom: 5px;
  }
  .order-product-group .order-product-select {
    padding: 5px;
    border: solid 0px #efefee;
  }
  .group-product {
    max-width: 31%;
    flex: 0 0 32%;
    margin: 0 5px;
    margin-bottom: 10px;
  }
  .row-skeleton .react-loading-skeleton {
    width: 100%;
  }
  .row-skeleton > span {
    max-width: 100%;
    flex: 0 0 31%;
    margin: 0 5px;
    margin-bottom: 25px;
    display: flex;
  }
}
@media (max-width: 576px) {
  .group-product:not(:nth-child(-n + 300)) {
    display: none;
  }
  .group-product {
    max-width: 100%;
    flex: 0 0 47%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .row-skeleton > span {
    max-width: 48%;
    flex: 0 0 48%;
    margin: 0 auto;
    margin-bottom: 10px;
    display: flex;
  }
  .row-skeleton .react-loading-skeleton {
    height: 200px !important;
  }
  .group-product-price {
    font-size: 1rem;
    word-break: break-word;
  }
  .group-product-name {
    font-size: 0.8rem;
  }
  .order-product-group {
    width: 100%;
  }
  .sidebar-groups {
    max-width: 80%;
  }
}
