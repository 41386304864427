.cart-container {
    max-width: 1366px;
    margin: auto;
}
.wrapper-table-cart {
    width: 100%;
}
.cart-product img {
    max-width: 75px;
}
.cart-product a {
    display: inline-block;
    margin: auto 13px;
    font-weight: 400;
    color: #333;
}
.cart-product a:hover {
    text-decoration: none;
}
.buttons-cart-action {
    align-items: center;
}
.buttons-cart-action button {
    border: solid 1px #f1f1f1;
}
.buttons-cart-action .total {
    min-width: 35px;
    transition: 0.2s ease-in;
}
.buttons-cart-action button:hover {
    background-color: #f1f1f1;
    color: #333;
}
.buttons-cart-action button:focus {
    outline: 0;
    box-shadow: none
}
.buttons-cart-action button:active {
    box-shadow: inset -2px 2px 2px #c9c9c9;
}
.product-details {
    margin: auto 10px;
}
.input-zip-cart {
    padding: 5px 10px;
    border-radius: 2px;
    border: solid 1px #cecece;
}
.input-zip-cart:focus {
    outline: 0;
    box-shadow: none;
}
.ring-2 {
    position: relative;
    width: 45px;
    height: 45px;
    margin: 0 auto;
    border: 7px solid #dc3545;
    border-radius: 100%;
    zoom: 50%;
    transform: translate(50%, -50%);
    margin-top: -35px;
    margin-right: 35px;
}
.ball-holder {
    position: absolute;
    width: 12px;
    height: 33px;
    left: 8px;
    top: 0px;
}
.ball {
    position: absolute;
    top: -11px;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: #333333;
}
@keyframes loadingE {
    0 {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.ball-holder {animation: loadingE 1.3s linear infinite;}
@media (max-width: 992px) {
    .buttons-cart-action button:active {
        box-shadow: none;
    }
}
@media (max-width: 640px) {
    .cart-product a {
        font-size: 1.2rem;
        line-height: 120%;
    }
    .cart-product {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: solid 1px #e6e6e6;
    }
}